import * as React from "react"
import { PropsWithChildren } from "react"

export interface IWrapperProps {}

// This component provides the nice padded wrapper around elements.
// It's used on the form page, link sharing page, and
// success page
export function WhitePaddedWrapper(props: PropsWithChildren<{}>) {
  const { children } = props
  return (
    <div className="container d-flex flex-column justify-content-center align-items-center">
      <div className="row justify-content-center">
        <div className="col bg-light p-6 rounded">
          <div className="max-width-peepo-form container">{children}</div>
        </div>
      </div>
    </div>
  )
}
